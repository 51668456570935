<template>
  <div class="filter-interval mt-4">
    <p class="filter-interval__title text-annotationColor text-sm font-SourceSansPro">
      {{ $t('reportsFilter.title') }}
    </p>
    <div class="flex align-center">
      <p class="text-annotationColor text-sm mb-2 font-SourceSansPro">
        {{ $t('reportsFilter.filterByTime') }}
      </p>
      <el-tooltip :open-delay="500" :content="$t('reportsFilter.filterHintSelectInterval')" :offset="2" :visible-arrow="false" effect="dark" placement="bottom">
        <span class="skif-info-icon m-0 ml-2">
          <InfoIcon :color="'#3A7CCA'" />
        </span>
      </el-tooltip>
    </div>
    <div class="filter-interval__add flex flex-wrap mb-4">
      <div class="intervals flex flex-wrap">
        <div v-for="(interval, index) in intervals" :key="index" class="interval mr-2 mb-2">
          <CloseIcon class="close-icon" @click.native="deleteInterval(interval, index)" />
          <span class="time">{{ interval.from }} - {{ interval.to }}</span>
        </div>
      </div>

      <div class="text-sm font-SourceSansPro font-semibold relative">
        <div class="filter-interval__add-interval" @click="isOpenTimepicker = true">
          <AddIcon :is-border="false" />
          <span class="filter-interval__text-add cursor-pointer"> {{ $t('reportsFilter.addInterval') }}</span>
        </div>

        <div v-if="isOpenTimepicker" class="filter-interval__modal-interval">
          <el-time-picker class="time-picker" v-model="timepicker" popper-class="filter-timepicker" format="HH:mm" />

          <el-time-picker class="time-picker" v-model="timepicker2" popper-class="filter-timepicker" format="HH:mm" />

          <button class="w-full mt-2 rounded-button transition-colors ease-in-out duration-500 font-semibold h-8 text-sm leading-none focus_outline-none w-44 bg-darkblue hover_bg-hoverButton text-white" :class="{ disabled: !timepicker || !timepicker2 }" :disabled="!timepicker || !timepicker2" @click="addInterval">
            {{ $t('reportsFilter.addInterval') }}
          </button>
          <!-- <p class="text-center text-red-200 p-2" v-else>
            {{ $t('reportsFilter.zeroInterval') }}
          </p> -->
        </div>
      </div>
    </div>
    <div class="filter-interval__days-interval">
      <p class="text-annotationColor text-sm font-SourceSansPro">
        {{ $t('reportsFilter.daysWeek') }}
      </p>
      <div class="days">
        <div v-for="day in days" :class="['day', { 'day--active': day.active }]" :key="day.key" @click="toggleActiveDay(day, days)">
          {{ day.name }}
        </div>
      </div>
    </div>
    <div class="flex mt-4">
      <span class="text-annotationColor text-sm font-SourceSansPro mr-2">{{ $t('reportsFilter.trimmerInterval') }}</span>
      <el-switch v-model="cropInterval" />
      <el-tooltip :open-delay="500" :content="$t('reportsFilter.filterHintFunction')" :offset="2" :visible-arrow="false" effect="dark" placement="bottom">
        <span class="skif-info-icon m-0 ml-2">
          <InfoIcon :color="'#3A7CCA'" />
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'FilterInterval',
  components: {
    AddIcon: () => import('@/components/reports/icons/AddIcon.vue'),
    InfoIcon: () => import('@/assets/svg-icons/info.svg'),
    CloseIcon: () => import('@/components/admin-panel/components/assets/icons/CloseIcon.vue'),
    FilterByGeozone: () => import('@/components/reports/components/FilterByGeozone.vue')
  },
  props: {
    filterIntervals: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isOpenTimepicker: false,
      days: [
        {
          key: 'Monday',
          dayNumber: 1,
          name: this.$t('routesTrips.trip.date.week_days.monday'),
          active: true
        },
        {
          key: 'Tuesday',
          dayNumber: 2,
          name: this.$t('routesTrips.trip.date.week_days.tuesday'),
          active: true
        },
        {
          key: 'Wednesday',
          dayNumber: 3,
          name: this.$t('routesTrips.trip.date.week_days.wednesday'),
          active: true
        },
        {
          key: 'Thursday',
          dayNumber: 4,
          name: this.$t('routesTrips.trip.date.week_days.thursday'),
          active: true
        },
        {
          key: 'Friday',
          dayNumber: 5,
          name: this.$t('routesTrips.trip.date.week_days.friday'),
          active: true
        },
        {
          key: 'Saturday',
          dayNumber: 6,
          name: this.$t('routesTrips.trip.date.week_days.saturday'),
          active: true
        },
        {
          key: 'Sunday',
          dayNumber: 7,
          name: this.$t('routesTrips.trip.date.week_days.sunday'),
          active: true
        }
      ],
      timepicker: null,
      timepicker2: null,
      intervals: [],
      selectDays: [],
      cropInterval: false,
      isSameDate: false,
      used_geozones_filter: false
    }
  },
  watch: {
    intervals(newValue) {
      this.$emit('change-intervals', newValue)
    },
    selectDays(newValue) {
      const inactiveKeys = newValue.map(item => item.key)
      const filteredDays = this.days.filter(item => inactiveKeys.includes(item.key))
      this.$emit('change-interval-days', filteredDays)
    },
    cropInterval(newValue) {
      this.$emit('change-interval-crop', newValue)
    }
  },

  computed: {
    // Проверка на нулевой интервал
    isSame() {
      if (this.timepicker != null && this.timepicker.length > 0) {
        return moment(this.timepicker[0]).format('HH:mm') === moment(this.timepicker[1]).format('HH:mm')
      } else {
        return false
      }
    }
  },
  methods: {
    addInterval() {
      this.isOpenTimepicker = false

      const newItem = {
        from: moment(this.timepicker).format('HH:mm'),
        to: moment(this.timepicker2).format('HH:mm')
      }

      this.intervals.push(newItem)

      this.timepicker = null
      this.timepicker2 = null
    },
    deleteInterval(interval, index) {
      this.intervals.splice(index, 1)
    },
    toggleActiveDay(day, array) {
      const index = array.findIndex(el => el.key === day.key)
      array[index].active = !array[index].active
      const dayIndex = this.selectDays.findIndex(el => el.key === day.key)
      if (dayIndex === -1) {
        this.selectDays.push(day)
      } else {
        this.selectDays.splice(dayIndex, 1)
      }
    },
    handleClickDaysPeriod(event) {
      const wrap = document.querySelector('.filter-interval__add')
      if (!wrap.contains(event.target)) {
        this.isOpenTimepicker = false
      }
    }
  },
  mounted() {
    // Хак когда обьект еще не отрисовался а клик сделан
    setTimeout(() => {
      document.addEventListener('click', this.handleClickDaysPeriod)
    }, 100)

    this.intervals = this.filterIntervals.intervals
    this.selectDays = this.days.filter(item => this.filterIntervals.days.includes(item.dayNumber))
    this.days = this.days.map(item => ({
      ...item,
      active: this.filterIntervals.days.includes(item.dayNumber)
    }))

    this.cropInterval = this.filterIntervals.crop_intervals
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickDaysPeriod)
  }
}
</script>

<style lang="scss" scoped>
.filter-interval {
  &__title {
    position: relative;
    color: #a6aeb8;
    margin-bottom: 10px;
    &:after {
      position: absolute;
      top: 50%;
      left: 90px;
      transform: translate(0%, -50%);
      content: '';
      width: 50%;
      height: 1px;
      background-color: #a6aeb8;
    }
  }
  &__add {
    align-items: center;
    color: #3a7cca;
    .intervals {
      .interval {
        display: flex;
        align-items: center;
        padding: 9.5px 14px 9.5px 14px;
        border-radius: 30px;
        background-color: #edeff3;
        font-size: 12px;
        color: #292929;
        .time {
          font-weight: 600;
        }
        .close-icon {
          margin-right: 12px;
          width: 11px;
          height: 11px;
          cursor: pointer;
        }
      }
    }
  }
  &__days-interval {
    .days {
      width: 256px;
      margin-top: 8px;
      display: flex;
      gap: 4px;
      .day {
        width: 100%;
        min-width: 46px;
        text-align: center;
        height: 32px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-radius: 2px;
        font-size: 14px;
        line-height: 16.8px;
        background-color: #edeff3;
        font-weight: 600;
        cursor: pointer;
        &:hover {
          background-color: #dce1e7;
        }
        &:last-child {
          border-right: none;
        }
        &--active {
          background-color: #5c9deb;
          color: #fff;
          &:hover {
            color: #292929;
          }
        }
      }
    }
  }
  &__modal-interval {
    position: absolute;
    bottom: -90px;
    left: 0;
    z-index: 2;
    background-color: #fff;
    width: 220px;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #edeff3;
    box-shadow: 0px 4px 14px 0px #00000014;


    .btn {
      margin-top: 6px;
    }
    :deep(.el-date-editor) {
      width: 49%;
      .filter-timepicker {
        width: 100px;
      }
    }
  }
}
</style>
